#login {
  margin-top: 120px !important;
  min-width: 320px;
  max-width: 350px;
}

.login-user {
  margin: 0 auto;
}

.headline {
  text-align: center;
  line-height: 35px;
}
.forgot-password {
  text-align: right;
}

a:hover {
  text-decoration: underline;
}

input {
  max-width: 330px;
}
