body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  line-height: 1.6;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: #2c3840;
  background-color: #f1f1f1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import './forms.scss';
@import './btns.scss';
