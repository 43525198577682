.btz:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.btz:active {
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: none;
}
.btz:focus {
  outline: 0;
}
.btz:disabled {
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: none;
}
.btz.-disabled {
  color: #000000;
  background: rgb(235, 235, 235);
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  opacity: 0.1;
}

.btz.-fill-1 {
  background: #2c3840;
  color: #f1f1f1;
  border: 1px solid #2c3840;
}
.btz.-fill-2 {
  color: #000000;
  background: #aabfcc;
  border: 1px solid #aabfcc;
}
.btz.-fill-3 {
  color: #000000;
  background: #cce4eb;
  border: 1px solid #cce4eb;
}
.btz.-fill-4 {
  color: #000000;
  background: #ffffff;
  border: 2px solid #ffffff;
}
.btz.-fill-5 {
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
}
.btz.-fill-6 {
  color: #000000;
  background: #bcd2ed;
}
.btz.-fill-7 {
  color: #000000;
  background: #ffffff;
}
.btz.-fill-8 {
  color: #ffffff;
  background: #10131a;
}

.btz.-fill-red {
  color: #000000;
  background: #d2042d;
}
.btz.-fill-amber {
  color: #000000;
  background: #ffbf00;
}
.btz.-fill-orange {
  color: #000000;
  background: #ff7629;
}
