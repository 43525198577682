input,
select,
option,
textarea {
  padding: 8px 10px;
  width: 100%;
  border: 1px solid #414953;
  border-radius: 6px;
  font-size: 1.1em;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #414953;
  // color: #333333;
  background-color: #f1f1f1;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
// textarea {
//   resize: none;
// }
textarea::-webkit-resizer {
  border-width: 3px;
  border-style: solid;
  border-color: transparent rgba(188, 210, 237, 0.3) rgba(188, 210, 237, 0.3) transparent;
}

label {
  font-weight: bold;
  text-transform: none;
  color: #ffffff;
  font-size: 1em;
}

.input-icon img {
  position: absolute;
  margin-top: 10px;
  margin-left: -30px;
}

.input-file-invisible {
  opacity: 0;
  position: absolute;
  padding: 0px;
  margin: 0px;
  width: inherit;
  border: none;
  border-radius: 0px;
  font-size: 1em;
  color: #ffffff;
  background-color: transparent;
}

/* ==== Place Holder ==== */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bebebe;
  opacity: 1; /* Firefox */
  font-weight: 500;
  // background-color: #2b323b;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bebebe;
  font-weight: 500;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bebebe;
  font-weight: 500;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* =================================== 
		SELECT BUTTONS 
====================================== */
.select-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}
.select-wrapper:hover input ~ .checkmark {
  background-color: #000000;
}
.select-wrapper input:checked ~ .checkmark {
  background-color: #000000;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.select-wrapper input:checked ~ .checkmark:after {
  display: block;
}
.select-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// @import '@/src/assets/css/mixins/content/multiStep.scss';

/* ======== CHECKBOX ======== */

/* Customize the label (the btn-container) */
.btn-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.btn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
}

/* On mouse-over, add a grey background color */
.btn-container:hover input ~ .checkmark {
  background-color: #00ceff;
}

/* When the checkbox is checked, add a blue background */
.btn-container input:checked ~ .checkmark {
  background-color: #00ceff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.btn-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.btn-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ======== Number Text UpDown Arrows Invisible ======== */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
